.mediaModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 115;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
  animation: 0.25s FadeIn forwards;
}

.mediaModal_container {
  position: relative;
  max-width: 75vw;
  max-height: calc(75vw * 0.6);
  opacity: 0;
  animation: 0.25s scaleUp forwards;
  animation-delay: 0.25s;
  background: #fff;
}

.mediaModal_container_closeBtn {
  position: absolute;
  top: -1.25rem;
  right: -1.25rem;
  width: 3rem;
  height: 3rem;
  z-index: 2;
  background-image: url(./closeBtn.png);
  background-size: contain;
  background-position: center;
  cursor: pointer;
  transform: scale(1);
  transition: 0.25s;
  border-radius: 50%;
}
.mediaModal_container_closeBtn:hover {
  /* transform: rotate(90deg); */
  transform: scale(1.2);
  box-shadow: 0px 0px 6px 2px #ffffff21;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes scaleUp {
  0% {
    opacity: 0;
    transform: scale(0.6);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.imageWrapper {
  max-width: 1024px;
}

.imageWrapper > img {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  border: 0.35rem solid white;
  border-radius: 3px;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.295);
}

.mediaModal_container iframe {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.mediaModal_container_loader {
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.mediaModalCloser {
  width: 100%;
  height: 100%;
  position: absolute;
}

.image_modal {
  max-width: 80vw;
  max-height: 80vh;
  position: relative;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 991px) and (orientation: landscape) {
  .w-image_modal {
    max-width: 80vw;
  }
  .h-image_modal {
    max-height: 80vh;
  }

  .mediaModal_container {
    position: relative;
    width: calc(75vh * 1.77);
    height: calc(75vh);
  }
}
@media only screen and (max-width: 991px) and (orientation: portrait) {
  .mediaModal_container {
    position: relative;
    width: 96vw;
    height: calc(96vw * 0.6);
  }
  .pdf-full {
    height: 75vh;
  }
  .mediaModal_container_closeBtn {
    position: absolute;
    top: 0;
    right: calc(50% - 1.5rem);
  }
}
